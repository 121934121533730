import { svgProps } from '@/types';
import React from 'react';

export const MykareBlogLogo: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='192.791'
      height='40.746'
      viewBox='0 0 192.791 40.746'
      className={className}
    >
      <g
        id='kareline_logo'
        data-name='kareline logo'
        transform='translate(-355.523 -334.518)'
      >
        <g
          id='Group_10700'
          data-name='Group 10700'
          transform='translate(355.524 334.518)'
        >
          <path
            id='Path_77681'
            data-name='Path 77681'
            d='M646.172,123.484a17,17,0,0,1,5.627-3.333,19.719,19.719,0,0,1,6.623-1.093,12.12,12.12,0,0,1,4.573.824,9.847,9.847,0,0,1,3.453,2.294,10.02,10.02,0,0,1,2.172,3.494,12.559,12.559,0,0,1,.754,4.425v17.482h-3.831a3.916,3.916,0,0,1-1.794-.323,2.469,2.469,0,0,1-1.012-1.349l-.594-1.538q-1.052.89-2.023,1.565a12.8,12.8,0,0,1-2.01,1.147,9.936,9.936,0,0,1-2.212.7,13.631,13.631,0,0,1-2.6.229,11.413,11.413,0,0,1-3.48-.5,7.255,7.255,0,0,1-2.657-1.469,6.463,6.463,0,0,1-1.684-2.414,8.705,8.705,0,0,1-.594-3.333,6.949,6.949,0,0,1,.755-3.062,7.555,7.555,0,0,1,2.616-2.831,16.638,16.638,0,0,1,5.018-2.145,33.69,33.69,0,0,1,7.958-.971v-1.19a5.133,5.133,0,0,0-1.012-3.548,3.718,3.718,0,0,0-2.873-1.093,7.614,7.614,0,0,0-2.428.323,10.9,10.9,0,0,0-1.684.729q-.742.4-1.443.729a3.969,3.969,0,0,1-1.673.323,2.408,2.408,0,0,1-1.457-.43,3.742,3.742,0,0,1-.971-1.025Zm15.052,12.707a27.743,27.743,0,0,0-4.128.43,10.023,10.023,0,0,0-2.563.809,3.1,3.1,0,0,0-1.308,1.106,2.618,2.618,0,0,0-.364,1.349,2.344,2.344,0,0,0,.782,2.01,3.825,3.825,0,0,0,2.294.58,6.637,6.637,0,0,0,2.831-.566,8.637,8.637,0,0,0,2.455-1.835Z'
            transform='translate(-614.865 -107.264)'
            fill='#00cfaf'
          />
          <path
            id='Path_77682'
            data-name='Path 77682'
            d='M828.75,123.9a11.76,11.76,0,0,1,2.981-3.561,6.183,6.183,0,0,1,3.871-1.295,4.991,4.991,0,0,1,3.075.89l-.539,6.151a1.294,1.294,0,0,1-.445.809,1.466,1.466,0,0,1-.85.215q-.215,0-.567-.027t-.729-.067l-.768-.081a6.964,6.964,0,0,0-.715-.04,4.244,4.244,0,0,0-2.927.971,7.64,7.64,0,0,0-1.848,2.7v17h-8.363V119.588h4.964a4.253,4.253,0,0,1,1.039.108,1.826,1.826,0,0,1,.7.337,1.441,1.441,0,0,1,.43.607,5.511,5.511,0,0,1,.256.917Z'
            transform='translate(-761.077 -107.253)'
            fill='#00cfaf'
          />
          <path
            id='Path_77683'
            data-name='Path 77683'
            d='M939.108,135.616a7.885,7.885,0,0,0,2.131,5.086,6.339,6.339,0,0,0,4.452,1.577,7.966,7.966,0,0,0,2.509-.351,12.622,12.622,0,0,0,1.875-.782q.825-.43,1.511-.783a3.143,3.143,0,0,1,1.443-.351,1.73,1.73,0,0,1,1.511.729l2.428,2.994a12.427,12.427,0,0,1-2.793,2.415,14.507,14.507,0,0,1-3.075,1.457,16.673,16.673,0,0,1-3.156.729,23.724,23.724,0,0,1-3.009.2,15.712,15.712,0,0,1-5.517-.958,12.668,12.668,0,0,1-4.505-2.846,13.416,13.416,0,0,1-3.048-4.695,17.535,17.535,0,0,1-1.119-6.528,14.773,14.773,0,0,1,.957-5.315,12.911,12.911,0,0,1,7.1-7.392,15.481,15.481,0,0,1,10.845-.243,11.062,11.062,0,0,1,3.965,2.509,11.453,11.453,0,0,1,2.59,4.033,14.8,14.8,0,0,1,.932,5.409,11.819,11.819,0,0,1-.081,1.538,2.436,2.436,0,0,1-.3.944,1.13,1.13,0,0,1-.58.486,2.711,2.711,0,0,1-.932.135Zm10.521-4.856a7.392,7.392,0,0,0-.256-1.942,4.717,4.717,0,0,0-.836-1.684,4.265,4.265,0,0,0-1.524-1.2,5.218,5.218,0,0,0-2.294-.458A5,5,0,0,0,941,126.836a7.1,7.1,0,0,0-1.781,3.925Z'
            transform='translate(-852.28 -107.795)'
            fill='#00cfaf'
          />
          <path
            id='Path_77684'
            data-name='Path 77684'
            d='M467.8,49.474l7.065,0a1.3,1.3,0,0,1,1.3,1.3V73.657l8.791-11.328a2.315,2.315,0,0,1,1.828-.9h9.483L485.641,75.105l11.827,14.679h-9.629a2.316,2.316,0,0,1-1.809-.869l-9.867-12.359v13.23l-8.367,0Z'
            transform='translate(-467.796 -49.474)'
            fill='#00cfaf'
          />
        </g>
        <path
          id='Path_256173'
          data-name='Path 256173'
          d='M7.293,0V-38.25H4.131V0ZM18.717,0V-26.52H15.555V0ZM14.739-33.1c0,3.111,4.794,3.111,4.794,0C19.737-36.159,14.535-36.159,14.739-33.1ZM26.979,0h3.162V-15.4a7.941,7.941,0,0,1,2.7-6.069,9.772,9.772,0,0,1,6.732-2.448,8.111,8.111,0,0,1,5.967,2.4,7.96,7.96,0,0,1,2.448,5.967V0h3.162V-16.269a10.227,10.227,0,0,0-3.111-7.65,10.415,10.415,0,0,0-7.7-3.06c-4.692,0-8.109,1.887-10.2,5.61V-26.52H26.979ZM84.66-12.291a4.707,4.707,0,0,0,.051-.969,13.26,13.26,0,0,0-3.876-9.792A13.116,13.116,0,0,0,71.2-26.979a13.116,13.116,0,0,0-9.639,3.927,13.26,13.26,0,0,0-3.876,9.792,13.148,13.148,0,0,0,3.978,9.792,13.689,13.689,0,0,0,10,3.927A13.409,13.409,0,0,0,83.487-5.967l-3.06-1.02A10.393,10.393,0,0,1,71.655-2.5a10.763,10.763,0,0,1-7.446-2.754,10.433,10.433,0,0,1-3.315-7.038ZM60.945-14.943a10.3,10.3,0,0,1,3.366-6.528,9.961,9.961,0,0,1,6.834-2.55,9.961,9.961,0,0,1,6.834,2.55,10.187,10.187,0,0,1,3.468,6.528Z'
          transform='translate(463.603 374.234)'
          fill='#00cfaf'
        />
      </g>
    </svg>
  );
};
export const KarePediaLogo: React.FC<svgProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
      width='137.172'
      height='87.492'
      viewBox='0 0 137.172 87.492'
      className={className}
    >
      <g
        id='Group_17456'
        data-name='Group 17456'
        transform='translate(-12.941 -8.254)'
      >
        <g
          id='Group_17455'
          data-name='Group 17455'
          transform='translate(12.941 8.254)'
          clip-path='url(#clip-path)'
        >
          <path
            id='Path_256196'
            data-name='Path 256196'
            d='M84.188,135.67l8.384-18.646,4.041-.816-.394.876a11.811,11.811,0,0,1,4.393-1.979c1.887-.381,3.183-.051,3.864.995s.6,2.542-.282,4.495a15.075,15.075,0,0,1-4.107,5.266,12.732,12.732,0,0,1-5.62,2.91,4.128,4.128,0,0,1-3.312-.423l-2.926,6.507Zm9.542-12.94c-.776,1.727-.022,2.826,1.729,2.473a5.912,5.912,0,0,0,2.588-1.341,6.557,6.557,0,0,0,1.906-2.387c.81-1.8.043-2.876-1.681-2.529a7.112,7.112,0,0,0-4.543,3.784'
            transform='translate(-43.587 -59.514)'
          />
          <path
            id='Path_256197'
            data-name='Path 256197'
            d='M132.579,117.195c-.162,1.284.754,1.918,2.4,1.587a7.1,7.1,0,0,0,3.293-1.712l3.535.037a16.161,16.161,0,0,1-8.3,4.883c-2.182.441-3.722.16-4.592-.848s-.866-2.488.012-4.44a14.244,14.244,0,0,1,4.311-5.286,14.522,14.522,0,0,1,6.2-3.048c2.1-.424,3.56-.127,4.392.911.842,1.013.811,2.5-.055,4.426a9.514,9.514,0,0,1-.769,1.384Zm7.715-4.083c.085-1.224-.788-1.844-2.3-1.539a7.547,7.547,0,0,0-4.143,2.839Z'
            transform='translate(-66.408 -56.009)'
          />
          <path
            id='Path_256198'
            data-name='Path 256198'
            d='M167.341,97.329a12.741,12.741,0,0,1,5.619-2.91,4.2,4.2,0,0,1,3.339.418l2.982-6.632,4.015-.81-8.44,18.77-4.015.81.405-.9a11.812,11.812,0,0,1-4.431,2.01c-1.886.381-3.183.052-3.863-.994-.67-1.071-.559-2.573.307-4.5a14.794,14.794,0,0,1,4.081-5.262m.133,4.388c-.811,1.8-.044,2.876,1.707,2.522a7.159,7.159,0,0,0,4.543-3.784,1.962,1.962,0,0,0,.074-2.018,1.647,1.647,0,0,0-1.8-.455,7.132,7.132,0,0,0-4.52,3.735'
            transform='translate(-84.141 -45.247)'
          />
          <path
            id='Path_256199'
            data-name='Path 256199'
            d='M193.912,104.726l5.851-13.015L203.8,90.9l-5.852,13.014Zm6.818-16.145a4.867,4.867,0,0,1,1.443-1.794,5.066,5.066,0,0,1,2.1-1.015c.727-.147,1.218-.041,1.485.291q.441.493-.015,1.506a4.735,4.735,0,0,1-1.469,1.8,4.881,4.881,0,0,1-2.071,1.01,1.512,1.512,0,0,1-1.512-.286c-.267-.333-.262-.835.042-1.51'
            transform='translate(-100.394 -44.373)'
          />
          <path
            id='Path_256200'
            data-name='Path 256200'
            d='M216.56,93.57a12.741,12.741,0,0,1,5.619-2.909,4.2,4.2,0,0,1,3.339.418l.394-.876,4.014-.811-5.851,13.015-4.015.81.405-.9a11.808,11.808,0,0,1-4.43,2.01c-1.886.381-3.184.052-3.864-.995-.671-1.071-.559-2.572.307-4.5a14.784,14.784,0,0,1,4.081-5.262m.133,4.388c-.81,1.8-.043,2.876,1.708,2.522a7.163,7.163,0,0,0,4.543-3.784,1.966,1.966,0,0,0,.074-2.018,1.649,1.649,0,0,0-1.8-.455,7.139,7.139,0,0,0-4.521,3.735'
            transform='translate(-109.623 -46.281)'
          />
          <path
            id='Path_256201'
            data-name='Path 256201'
            d='M70.756,27.949l4.583-.713,9.683,11.608-.06-6.155,5.408-.841.1,7.568,12.082,6.249-5.5.855L87.1,41.331,92.072,47.3l-4.583.713Z'
            transform='translate(-36.632 -14.101)'
          />
          <path
            id='Path_256202'
            data-name='Path 256202'
            d='M122.74,34.848c.276-1.614,1.5-2.589,3.637-2.922a11.944,11.944,0,0,1,5.471.6l-.781-.937,4.553-.708,11.6,13.908-4.553.708-.8-.963a4.1,4.1,0,0,1-3.307,1.995,12.646,12.646,0,0,1-7.118-1.251,18.063,18.063,0,0,1-6.489-4.883c-1.74-2.086-2.488-3.934-2.212-5.548m6.956,4.78c1.606,1.926,4.369,3.128,6.356,2.819,1.955-.3,2.34-2,.733-3.921a7.834,7.834,0,0,0-3-2.192,6.04,6.04,0,0,0-3.308-.573c-1.986.309-2.317,2.022-.778,3.868'
            transform='translate(-63.517 -15.988)'
          />
          <path
            id='Path_256203'
            data-name='Path 256203'
            d='M163.788,28.345c.066-1.37,1.194-2.21,3.363-2.547l3.569,4.279a4.637,4.637,0,0,0-1.962-.118c-1.834.285-2.182,1.729-.821,3.36l6.247,7.489-4.584.712L158,27.612l4.583-.712Z'
            transform='translate(-81.801 -13.356)'
          />
          <path
            id='Path_256204'
            data-name='Path 256204'
            d='M198.122,31.512a8.075,8.075,0,0,0,5.91,1.83c1.314-.2,2.018-.766,2.122-1.719l5.167.194c.5,2.55-1.071,4.427-4.463,4.954a14.956,14.956,0,0,1-7.943-1.122,16.964,16.964,0,0,1-6.826-4.831c-1.718-2.059-2.373-3.921-1.914-5.563.437-1.67,1.843-2.673,4.257-3.048a14.3,14.3,0,0,1,7.752,1.182,16.989,16.989,0,0,1,6.742,4.814,9.1,9.1,0,0,1,1.021,1.473Zm4.856-4.109a8.116,8.116,0,0,0-5.691-1.775,1.906,1.906,0,0,0-1.612,2.91Z'
            transform='translate(-98.385 -11.426)'
          />
          <path
            id='Path_256205'
            data-name='Path 256205'
            d='M36.254,62.271l2.679,3.295c.571.7,1.242.61,1.5-.207a3.314,3.314,0,0,0-.57-2.751l-2.679-3.295c-.571-.7-1.242-.61-1.5.207a3.312,3.312,0,0,0,.57,2.751'
            transform='translate(-18.423 -30.463)'
          />
          <path
            id='Path_256206'
            data-name='Path 256206'
            d='M24.9,110.271l-2.679-3.295c-.571-.7-1.242-.61-1.5.207a3.314,3.314,0,0,0,.57,2.751l2.679,3.295c.571.7,1.242.61,1.5-.207a3.312,3.312,0,0,0-.57-2.751'
            transform='translate(-10.679 -55.14)'
          />
          <path
            id='Path_256207'
            data-name='Path 256207'
            d='M32.515,74.187l7.867,9.676c.571.7,1.242.61,1.5-.207a3.311,3.311,0,0,0-.57-2.751l-7.867-9.677c-.571-.7-1.242-.61-1.5.207a3.313,3.313,0,0,0,.571,2.751'
            transform='translate(-16.487 -36.633)'
          />
          <path
            id='Path_256208'
            data-name='Path 256208'
            d='M33.832,104.737,25.964,95.06c-.571-.7-1.242-.61-1.5.207a3.312,3.312,0,0,0,.57,2.751l7.867,9.676c.571.7,1.242.61,1.5-.207a3.314,3.314,0,0,0-.57-2.751'
            transform='translate(-12.615 -48.971)'
          />
          <path
            id='Path_256209'
            data-name='Path 256209'
            d='M42.826,99.286,29.7,83.144c-.571-.7-1.242-.61-1.5.207a3.311,3.311,0,0,0,.57,2.751L41.9,102.244c.571.7,1.242.61,1.5-.207a3.313,3.313,0,0,0-.57-2.751'
            transform='translate(-14.551 -42.802)'
          />
          <path
            id='Path_256210'
            data-name='Path 256210'
            d='M30.8,96.416a13.491,13.491,0,0,1-10.214-5.109L.247,64.617a1.206,1.206,0,0,1,1.918-1.462L22.525,89.873a10.546,10.546,0,0,0,11.5,3.634l.086-.023,67.116-14.963,12.7-2.734c.16-.022,4.216-.638,6.769-6.576l.036-.075L133.305,44.7c.17-.272,4.147-6.8-1.7-12.317L118.058,19.309a1.206,1.206,0,1,1,1.674-1.735l13.541,13.062c6.967,6.572,2.564,14.513,2.106,15.291L122.9,70.2c-2.976,6.885-7.929,7.866-8.616,7.97l-12.535,2.7L34.68,95.829a13.522,13.522,0,0,1-3.879.587'
            transform='translate(0 -8.923)'
          />
          <path
            id='Path_256211'
            data-name='Path 256211'
            d='M42.133,86.293a1.207,1.207,0,0,1-1.118-1.656L55.752,47.925a6.589,6.589,0,0,0-1.071-6.7L33.543,16.052l-3.056-3.719a3.855,3.855,0,0,1-.856-3.2c.28-1.4,1.424-3.152,5.284-3.526L83.708.009a1.206,1.206,0,1,1,.275,2.4l-48.815,5.6c-1.1.107-2.944.453-3.173,1.6a1.478,1.478,0,0,0,.354,1.2l3.048,3.71,21.13,25.162a8.995,8.995,0,0,1,1.461,9.152L43.252,85.537a1.207,1.207,0,0,1-1.119.757'
            transform='translate(-15.305 0)'
          />
          <path
            id='Path_256212'
            data-name='Path 256212'
            d='M104.973,79.753a1.206,1.206,0,0,1-.19-2.4l54.636-8.749a1.206,1.206,0,1,1,.382,2.381l-54.636,8.75a1.234,1.234,0,0,1-.192.015'
            transform='translate(-53.724 -35.512)'
          />
          <path
            id='Path_256213'
            data-name='Path 256213'
            d='M20.544,21.622a1.207,1.207,0,0,1-1.138-1.605,12.865,12.865,0,0,1,10.691-8.6q.315-.033.645-.053a1.205,1.205,0,1,1,.146,2.407q-.276.017-.543.045a10.444,10.444,0,0,0-8.664,7,1.207,1.207,0,0,1-1.138.807'
            transform='translate(-10.012 -5.882)'
          />
        </g>
      </g>
    </svg>
  );
};
export const Notification = ({ className }: svgProps) => {
  return (
    <svg
      id='notification'
      xmlns='http://www.w3.org/2000/svg'
      width='20.003'
      height='22.094'
      viewBox='0 0 23.003 25.094'
      className='m-auto'
    >
      <path
        className={className}
        id='Path_17'
        data-name='Path 17'
        d='M137.253,420.705a3.926,3.926,0,0,1-3.921-3.921.784.784,0,0,1,1.568,0,2.353,2.353,0,0,0,4.705,0,.784.784,0,0,1,1.568,0A3.926,3.926,0,0,1,137.253,420.705Zm0,0'
        transform='translate(-126.797 -395.611)'
        fill='#1c2534'
      />
      <path
        className={className}
        id='Path_18'
        data-name='Path 18'
        d='M19.082,82.821H1.83A1.83,1.83,0,0,1,.64,79.6a.722.722,0,0,1,.084-.063,7.027,7.027,0,0,0,2.413-5.3V71.319A7.328,7.328,0,0,1,10.456,64a3.15,3.15,0,0,1,.516.031.784.784,0,1,1-.257,1.546,1.645,1.645,0,0,0-.258-.009,5.757,5.757,0,0,0-5.751,5.751v2.917A8.6,8.6,0,0,1,1.67,80.793c-.016.013-.029.024-.046.036a.259.259,0,0,0-.055.162.264.264,0,0,0,.261.261H19.082a.264.264,0,0,0,.262-.261.25.25,0,0,0-.056-.162l-.045-.036a8.6,8.6,0,0,1-3.035-6.557V73.1a.784.784,0,0,1,1.568,0v1.14a7.03,7.03,0,0,0,2.416,5.3.761.761,0,0,1,.08.061,1.829,1.829,0,0,1-1.19,3.219Zm0,0'
        transform='translate(0 -60.864)'
        fill='#1c2534'
      />
      <path
        className={className}
        id='Path_19'
        data-name='Path 19'
        d='M261.228,10.456a5.228,5.228,0,1,1,5.228-5.228A5.234,5.234,0,0,1,261.228,10.456Zm0-8.887a3.659,3.659,0,1,0,3.659,3.66,3.663,3.663,0,0,0-3.659-3.66Zm0,0'
        transform='translate(-243.453)'
        fill='#1c2534'
      />
    </svg>
  );
};

export const SunIcon = ({ className }: svgProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.524'
      height='17.524'
      viewBox='0 0 17.524 17.524'
      className='m-auto'
    >
      <circle
        data-name='Ellipse 244'
        cx='4.5'
        cy='4.5'
        r='4.5'
        transform='translate(4.271 4.271)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256012'
        d='M11.73 20a.73.73 0 0 0-.73.73v1.46a.73.73 0 0 0 1.46 0v-1.46a.73.73 0 0 0-.73-.73z'
        transform='translate(-2.968 -5.396)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256013'
        d='M11.73 2.921a.73.73 0 0 0 .73-.73V.73a.73.73 0 1 0-1.46 0v1.46a.73.73 0 0 0 .73.731z'
        transform='translate(-2.968)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256014'
        d='M2.921 11.73a.73.73 0 0 0-.73-.73H.73a.73.73 0 1 0 0 1.46h1.46a.73.73 0 0 0 .731-.73z'
        transform='translate(0 -2.968)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256015'
        d='M22.191 11H20.73a.73.73 0 0 0 0 1.46h1.46a.73.73 0 0 0 0-1.46z'
        transform='translate(-5.396 -2.968)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256016'
        d='M18.61 17.578a.73.73 0 1 0-1.033 1.033l1.033 1.032a.739.739 0 0 0 1.032 0 .73.73 0 0 0 0-1.032z'
        transform='translate(-4.685 -4.685)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256017'
        d='M4.468 5.5a.738.738 0 0 0 1.032 0 .73.73 0 0 0 0-1.032L4.468 3.436a.73.73 0 1 0-1.032 1.033z'
        transform='translate(-.869 -.869)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256018'
        d='M4.468 17.578 3.436 18.61a.73.73 0 0 0 0 1.032.738.738 0 0 0 1.032 0L5.5 18.61a.73.73 0 1 0-1.033-1.033z'
        transform='translate(-.869 -4.685)'
        fill='#8c979e'
        className={className}
      />
      <path
        data-name='Path 256019'
        d='M18.093 5.706a.726.726 0 0 0 .516-.205l1.032-1.032a.73.73 0 1 0-1.033-1.033l-1.031 1.032a.721.721 0 0 0 .516 1.238z'
        transform='translate(-4.684 -.869)'
        fill='#8c979e'
        className={className}
      />
    </svg>
  );
};
export const HalfMoonIcon = ({ className }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='15.258'
    height='15.258'
    viewBox='0 0 15.258 15.258'
    className='m-auto'
  >
    <g data-name='Half Moon'>
      <path
        data-name='Path 256020'
        d='M15.207 11.239a.238.238 0 0 0-.265-.079 8.031 8.031 0 0 1-2.607.429 7.572 7.572 0 0 1-7.74-7.373A7.085 7.085 0 0 1 5.733.368a.238.238 0 0 0-.277-.354A7.9 7.9 0 0 0 0 7.409a8.049 8.049 0 0 0 8.217 7.85 8.256 8.256 0 0 0 7-3.743.238.238 0 0 0-.013-.276z'
        transform='translate(0 -.001)'
        className={className}
      />
      <path
        data-name='Path 256022'
        d='M46.238 13.669a.954.954 0 0 1 .954.954.238.238 0 0 0 .477 0 .954.954 0 0 1 .954-.954.238.238 0 1 0 0-.477.954.954 0 0 1-.954-.954.238.238 0 1 0-.477 0 .954.954 0 0 1-.954.954.238.238 0 0 0 0 .477z'
        transform='translate(-35.034 -9.139)'
        className={className}
      />
      <path
        data-name='Path 256023'
        d='M41 32.43a.238.238 0 0 0 .238.238.954.954 0 0 1 .954.954.238.238 0 0 0 .477 0 .954.954 0 0 1 .954-.954.238.238 0 0 0 0-.477.954.954 0 0 1-.954-.954.238.238 0 0 0-.477 0 .954.954 0 0 1-.954.954.238.238 0 0 0-.238.239z'
        transform='translate(-31.226 -23.61)'
        className={className}
      />
    </g>
  </svg>
);
export const SearchIcon = ({ className }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17.608'
    viewBox='0 0 17 17.608'
    className='m-auto mr-[8px]'
  >
    <path
      d='m17.725 16.038-4.19-4.358a7.106 7.106 0 1 0-5.441 2.539 7.033 7.033 0 0 0 4.073-1.286l4.223 4.392a.927.927 0 1 0 1.337-1.286zM8.093 1.855a5.255 5.255 0 1 1-5.254 5.254 5.261 5.261 0 0 1 5.254-5.254z'
      transform='translate(-.984)'
      className={className}
    />
  </svg>
);
export const ShareIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23.892'
    height='20'
    viewBox='0 0 23.892 20'
    className={className}
  >
    <path
      d='m23.077 47.786-5.408-5.408a1.946 1.946 0 0 0-1.345-.673c-.613 0-1.329.466-1.329 1.78v1.841A15.7 15.7 0 0 0 0 61.005a.7.7 0 0 0 1.26.42 17.9 17.9 0 0 1 13.735-7.2v1.813c0 1.314.716 1.78 1.329 1.78a1.946 1.946 0 0 0 1.345-.673l5.408-5.408a2.8 2.8 0 0 0 0-3.948zm-.99 2.958-5.408 5.409a1.487 1.487 0 0 1-.253.209 1.487 1.487 0 0 1-.031-.327v-2.526a.7.7 0 0 0-.7-.7 19.235 19.235 0 0 0-8.833 2.118 19.609 19.609 0 0 0-5.309 3.984 14.318 14.318 0 0 1 14.142-12.2.7.7 0 0 0 .7-.7v-2.526a1.49 1.49 0 0 1 .031-.327 1.487 1.487 0 0 1 .253.209l5.408 5.408a1.4 1.4 0 0 1 0 1.968z'
      transform='translate(0 -41.705)'
      className={color}
    />
  </svg>
);
export const ToggleButtonIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='19.679'
    height='15.306'
    viewBox='0 0 19.679 15.306'
  >
    <defs></defs>
    <path
      id='menu'
      className={color}
      d='M3,6.093A1.093,1.093,0,0,1,4.093,5H21.585a1.093,1.093,0,0,1,0,2.187H4.093A1.093,1.093,0,0,1,3,6.093Zm0,6.56A1.093,1.093,0,0,1,4.093,11.56H15.026a1.093,1.093,0,0,1,0,2.187H4.093A1.093,1.093,0,0,1,3,12.653Zm0,6.56a1.093,1.093,0,0,1,1.093-1.093H9.56a1.093,1.093,0,1,1,0,2.187H4.093A1.093,1.093,0,0,1,3,19.212Z'
      transform='translate(-3 -5)'
    />
  </svg>
);

export const LikeIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24.3'
    height='24.298'
    viewBox='0 0 24.3 24.298'
    className={className}
  >
    <g id='manual-voting' transform='translate(0.15 0.148)'>
      <g id='Group_14825' data-name='Group 14825'>
        <g id='Group_14824' data-name='Group 14824'>
          <path
            id='Path_253506'
            data-name='Path 253506'
            d='M17.5,24H.5a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Z'
            className={color}
          />
        </g>
      </g>
      <g id='Group_14827' data-name='Group 14827'>
        <g id='Group_14826' data-name='Group 14826'>
          <path
            id='Path_253507'
            data-name='Path 253507'
            d='M21.368,9.688h0A1.493,1.493,0,0,1,20.3,9.243L14.777,3.656a1.5,1.5,0,0,1,.007-2.115l1.1-1.1a1.5,1.5,0,0,1,2.121,0L23.561,6a1.5,1.5,0,0,1,0,2.122L22.429,9.25A1.5,1.5,0,0,1,21.368,9.688ZM16.944,1a.5.5,0,0,0-.354.146l-1.1,1.1a.5.5,0,0,0,0,.7l5.524,5.587a.5.5,0,0,0,.354.148.717.717,0,0,0,.355-.147l1.132-1.131a.5.5,0,0,0,0-.708L17.3,1.145A.5.5,0,0,0,16.944,1Zm-1.806.893h.01Z'
            className={color}
          />
        </g>
      </g>
      <g id='Group_14829' data-name='Group 14829'>
        <g id='Group_14828' data-name='Group 14828'>
          <path
            id='Path_253508'
            data-name='Path 253508'
            d='M5.854,12.586a.5.5,0,0,1-.479-.356,6.154,6.154,0,0,1-.261-1.774A6.28,6.28,0,0,1,5.765,7.7,6.206,6.206,0,0,1,9.791,4.461l5.47-1.39a.5.5,0,1,1,.246.968l-5.47,1.39A5.208,5.208,0,0,0,6.661,8.14a5.264,5.264,0,0,0-.547,2.316,5.166,5.166,0,0,0,.218,1.487A.5.5,0,0,1,6,12.566a.548.548,0,0,1-.143.02Z'
            className={color}
          />
        </g>
      </g>
      <g id='Group_14831' data-name='Group 14831'>
        <g id='Group_14830' data-name='Group 14830'>
          <path
            id='Path_253509'
            data-name='Path 253509'
            d='M11.234,19.492a2.244,2.244,0,0,1-1.943-1.107,2.327,2.327,0,0,1,.413-2.82l3.531-3.537a2.091,2.091,0,0,0-3.417.307.5.5,0,0,1-.87-.5,3.091,3.091,0,0,1,5.194-.272l.156.217a.5.5,0,0,1-.044.637l-3.844,3.85a1.344,1.344,0,0,0-.262,1.6,1.3,1.3,0,0,0,1.991.245l4.342-4.41,3.481-5.364a.5.5,0,1,1,.837.544L17.29,14.3a.482.482,0,0,1-.063.08l-4.379,4.448a2.26,2.26,0,0,1-1.614.669Z'
            className={color}
          />
        </g>
      </g>
      <g id='Group_14833' data-name='Group 14833'>
        <g id='Group_14832' data-name='Group 14832'>
          <path
            id='Path_253510'
            data-name='Path 253510'
            d='M15.517,24H2.5a.5.5,0,0,1-.5-.5v-11a.5.5,0,0,1,.5-.5H13.471a.5.5,0,1,1,0,1H3V23H15.017V15.4a.5.5,0,0,1,1,0v8.1A.5.5,0,0,1,15.517,24Z'
            className={color}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const VolumeIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22.835'
    height='21.314'
    viewBox='0 0 22.835 21.314'
    className={className}
  >
    <g data-name='Group 14816'>
      <g data-name='Group 14815'>
        <path
          data-name='Path 253502'
          d='M13.27 16.066a.759.759 0 0 0-.805.091l-7.4 5.924H1.522A1.525 1.525 0 0 0 0 23.6v6.089a1.525 1.525 0 0 0 1.522 1.522H5.06l7.4 5.924a.762.762 0 0 0 1.238-.6V16.752a.764.764 0 0 0-.428-.686zm-8.7 13.627H1.522V23.6h3.045zm7.612 5.266-6.093-4.872v-6.878l6.089-4.872z'
          transform='translate(0 -15.99)'
          className={color}
        />
      </g>
    </g>
    <g data-name='Group 14818'>
      <g data-name='Group 14817'>
        <path
          data-name='Path 253503'
          d='M343.057 122.449a.761.761 0 1 0-1.07 1.082 6.079 6.079 0 0 1 0 8.6.761.761 0 1 0 1.07 1.081 7.6 7.6 0 0 0 0-10.765z'
          transform='translate(-325.501 -117.175)'
          className={color}
        />
      </g>
    </g>
    <g data-name='Group 14820'>
      <g data-name='Group 14819'>
        <path
          data-name='Path 253504'
          d='M388.185 77.357a.761.761 0 0 0-1.072 1.081 9.089 9.089 0 0 1 0 12.894.761.761 0 1 0 1.072 1.081 10.611 10.611 0 0 0 0-15.056z'
          transform='translate(-368.482 -74.228)'
          className={color}
        />
      </g>
    </g>
  </svg>
);

export const LikeIconBlog = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22.571'
    height='23.214'
    viewBox='0 0 22.571 23.214'
    className={className}
  >
    <path
      data-name='Path 253511'
      d='M28.375 15.646a2.859 2.859 0 0 0 .669-1.911 2.935 2.935 0 0 0-.626-1.656 2.572 2.572 0 0 0-.611-2.96 5.314 5.314 0 0 0-3.865-.919 15.1 15.1 0 0 0-2.368.3c-.241.043-.5.1-.756.154a8.4 8.4 0 0 1 .6-2.8c.674-2.05.635-3.619-.125-4.668A3.059 3.059 0 0 0 18.841 0a1.209 1.209 0 0 0-.929.424 3.238 3.238 0 0 0-.4 2.3c-.635 1.7-2.416 5.881-3.922 7.041a.515.515 0 0 0-.077.067 5.17 5.17 0 0 0-.943 1.415 1.986 1.986 0 0 0-.957-.247H8.677a2 2 0 0 0-2 2v7.82a2 2 0 0 0 2 2h2.936a2 2 0 0 0 1.155-.366l1.131.135a48.454 48.454 0 0 0 6.415.351 21.71 21.71 0 0 0 1.612.067 10.8 10.8 0 0 0 2.238-.2 4.185 4.185 0 0 0 2.989-1.858 2.946 2.946 0 0 0 .327-1.843 3.07 3.07 0 0 0 1.092-2.5 3.251 3.251 0 0 0-.197-.96zm-19.7 5.881a.7.7 0 0 1-.7-.7V13a.7.7 0 0 1 .7-.7h2.936a.7.7 0 0 1 .7.7v7.82a.7.7 0 0 1-.7.7H8.677zm18.466-6.444a.647.647 0 0 0-.087.784 2 2 0 0 1 .221.8 1.926 1.926 0 0 1-.9 1.665.656.656 0 0 0-.221.741 1.7 1.7 0 0 1-.13 1.242 3.01 3.01 0 0 1-2.127 1.222 12.678 12.678 0 0 1-3.508.106h-.067a47.5 47.5 0 0 1-6.256-.342l-.486-.058a2.039 2.039 0 0 0 .043-.424V13a2.036 2.036 0 0 0-.091-.6 4.022 4.022 0 0 1 .9-1.651c2.161-1.713 4.274-7.493 4.365-7.743a.609.609 0 0 0 .029-.322 3.008 3.008 0 0 1 .063-1.4 1.727 1.727 0 0 1 1.357.65c.491.679.472 1.891-.058 3.5-.809 2.45-.876 3.739-.236 4.307a1.049 1.049 0 0 0 1.049.188c.294-.067.573-.125.837-.168l.063-.014c1.477-.322 4.124-.52 5.044.318a1.385 1.385 0 0 1 .164 1.757.644.644 0 0 0 .116.837 1.823 1.823 0 0 1 .534 1.121 1.787 1.787 0 0 1-.616 1.303z'
      transform='translate(-6.575 .1)'
      className={color}
    />
  </svg>
);

export const CloseIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.073'
    height='18.074'
    viewBox='0 0 18.073 18.074'
    className={className}
  >
    <path
      id='close'
      d='M5.071,20.666a1.239,1.239,0,0,0,1.752,1.752L13.744,15.5l6.921,6.921a1.239,1.239,0,0,0,1.752-1.752L15.5,13.744l6.921-6.922A1.239,1.239,0,0,0,20.664,5.07l-6.921,6.922L6.823,5.07A1.239,1.239,0,0,0,5.07,6.822l6.921,6.921Z'
      transform='translate(-4.707 -4.707)'
      className={color}
    />
  </svg>
);
export const PrevBtnIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='previous'
    width='20.408'
    height='20.408'
    viewBox='0 0 20.408 20.408'
    className={className}
  >
    <g id='Group_17225' data-name='Group 17225'>
      <g id='Group_17224' data-name='Group 17224'>
        <path
          id='Path_256009'
          data-name='Path 256009'
          d='M10.2,0a10.2,10.2,0,1,0,10.2,10.2A10.2,10.2,0,0,0,10.2,0Zm0,18.708a8.5,8.5,0,1,1,8.5-8.5A8.5,8.5,0,0,1,10.2,18.708Z'
          className={color}
        />
        <path
          id='Path_256010'
          data-name='Path 256010'
          d='M176.37,128.249a.85.85,0,0,0-1.2,0l-4.252,4.252a.85.85,0,0,0,0,1.2l4.252,4.252a.85.85,0,1,0,1.2-1.2l-3.65-3.65,3.65-3.65A.85.85,0,0,0,176.37,128.249Z'
          transform='translate(-163.864 -122.898)'
          className={color}
        />
      </g>
    </g>
  </svg>
);
export const NextBtnIcon = ({ className, color }: svgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='next'
    width='20.408'
    height='20.408'
    viewBox='0 0 20.408 20.408'
    className={className}
  >
    <g id='Group_17225' data-name='Group 17225'>
      <g id='Group_17224' data-name='Group 17224'>
        <path
          id='Path_256009'
          data-name='Path 256009'
          d='M10.2,0A10.2,10.2,0,1,1,0,10.2,10.2,10.2,0,0,1,10.2,0Zm0,18.708a8.5,8.5,0,1,0-8.5-8.5A8.5,8.5,0,0,0,10.2,18.708Z'
          className={color}
        />
        <path
          id='Path_256010'
          data-name='Path 256010'
          d='M170.916,128.249a.85.85,0,0,1,1.2,0l4.252,4.252a.85.85,0,0,1,0,1.2l-4.252,4.252a.85.85,0,0,1-1.2-1.2l3.65-3.65-3.65-3.65A.85.85,0,0,1,170.916,128.249Z'
          transform='translate(-163.014 -122.898)'
          className={color}
        />
      </g>
    </g>
  </svg>
);
