import FeatureImage from '@/public/homePage/FeatureImage.webp';

export function getStrapiMedia(media) {
  let url;
  if (media?.data?.length) {
    url = media?.data[0]?.attributes?.url;
  } else {
    url = media?.data?.attributes?.url;
  }

  if (!url) {
    return FeatureImage;
  }
  let imageUrl = url;
  const parsedUrl = new URL(url);
  if (parsedUrl.hostname === `mykare-blog-assets.s3.ap-south-1.amazonaws.com`)
    imageUrl = `${process.env.NEXT_PUBLIC_CDN_URL}${parsedUrl?.pathname}`;
  return imageUrl;
}

export function getStrapiMediaFromMd(url) {
  let imageUrl = url;
  const parsedUrl = new URL(url);
  if (parsedUrl.hostname === `mykare-blog-assets.s3.ap-south-1.amazonaws.com`)
    imageUrl = `${process.env.NEXT_PUBLIC_CDN_URL}${parsedUrl?.pathname}`;
  return imageUrl;
}
