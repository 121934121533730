import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { THEME_TYPES } from '@/constants';
const { THEME_LIGHT, THEME_DARK } = THEME_TYPES;

const useThemeStore = create(
  persist(
    (set) => ({
      theme: THEME_LIGHT,
      toggleTheme: () =>
        set((state) => ({
          theme: state.theme === THEME_LIGHT ? THEME_DARK : THEME_LIGHT,
        })),
      font: {
        heading: 'heading-TextSmall',
        title: 'title-TextSmall',
        body: 'body-TextSmall',
        bodySmall: 'bodySmall-TextSmall',
        blogBody: 'blogBody-TextSmall',
        footNote: 'footNote-TextSmall',
      },
      setFontHeading: (id) => {
        let fontsize;
        switch (id) {
          case 'small':
            fontsize = {
              heading: 'heading-TextSmall',
              title: 'title-TextSmall',
              body: 'body-TextSmall',
              blogBody: 'blogBody-TextSmall',
              bodySmall: 'bodySmall-TextSmall',
              footNote: 'footNote-TextSmall',
            };
            break;
          case 'medium':
            fontsize = {
              heading: 'heading-TextMedium',
              title: 'title-TextMedium',
              body: 'body-TextMedium',
              blogBody: 'blogBody-TextMedium',
              bodySmall: 'bodySmall-TextMedium',
              footNote: 'footNote-TextMedium',
            };
            break;
          case 'large':
            fontsize = {
              heading: 'heading-TextLarge',
              title: 'title-TextLarge',
              body: 'body-TextLarge',
              blogBody: 'blogBody-TextLarge',
              bodySmall: 'bodySmall-TextLarge',
              footNote: 'footNote-TextLarge',
            };
            break;
        }
        set({ font: fontsize });
      },
      category: null,
      setCategory: (value) => set({ category: value }),
      showToast: false,
      setShowToast: (value) => set({ showToast: value }),
      copyUrlToast: false,
      setCopyUrlToast: (value) => set({ copyUrlToast: value }),
    }),
    {
      name: 'theme',
    }
  )
);

export default useThemeStore;
